<template>
  <div class="my-4 text-blue-500">
    <router-link
      v-if="internal && !publicDoc"
      :to="{
        name: 'view-document',
        query: { document },
      }"
    >
      {{ title }}
    </router-link>
    <router-link
      v-else-if="internal && publicDoc"
      :to="{
        name: 'public-privacy-document',
        params: { title: document },
      }"
      target="_blank"
    >
      {{ title }}
    </router-link>
    <a v-else :href="href" target="_blank">{{ title }}</a>
  </div>
</template>
<script>
import { replaceS3 } from '../../../utils/appConfig'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    document: {
      type: String,
      required: true,
    },
    internal: {
      type: Boolean,
      required: true,
    },
    publicDoc: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    href() {
      return replaceS3(this.document)
    },
  },
}
</script>
