<template>
  <div class="w-full max-w-3xl py-20 bg-white mx-auto">
    <Back
      :route="{ name: 'help-center' }"
      title="help_center.back_to_landing"
      class="my-15 inline-block"
    />
    <h4 class="mt-20">{{ title }}</h4>
    <router-link
      v-for="item in items"
      :key="item.id"
      class="flex items-center justify-between border-b border-solid py-20 cursor-pointer"
      :to="{ name: 'faq-item', params: { id: item.id } }"
    >
      <span>{{ item.question }}</span>
      <Icon iconClass="fas fa-chevron-right" />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _get from 'lodash/get'
import _head from 'lodash/head'

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('faq', ['itemsByCategoryId']),
    items() {
      return this.itemsByCategoryId(this.id)
    },
    title() {
      return _get(_head(this.items), 'category')
    },
  },
}
</script>

<style></style>
