var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-4 text-blue-500"},[(_vm.internal && !_vm.publicDoc)?_c('router-link',{attrs:{"to":{
      name: 'view-document',
      query: { document: _vm.document },
    }}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):(_vm.internal && _vm.publicDoc)?_c('router-link',{attrs:{"to":{
      name: 'public-privacy-document',
      params: { title: _vm.document },
    },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('a',{attrs:{"href":_vm.href,"target":"_blank"}},[_vm._v(_vm._s(_vm.title))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }