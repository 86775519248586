<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  created() {
    this.initFaq()
  },
  methods: {
    ...mapActions('faq', ['initFaq']),
  },
}
</script>

<style></style>
