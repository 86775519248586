<template>
  <div class="mt-20 mb-20 border rounded-lg border-grey-300 md:mt-0">
    <h5 class="px-40 py-20 border-b border-grey-300">
      {{ $t('help_center.important_documents') }}
    </h5>
    <div class="px-40 py-20">
      <DocumentLink
        v-for="doc in docs"
        :key="doc.title"
        :title="doc.title"
        :document="doc.document"
        :internal="!!doc.internal"
        :publicDoc="!!doc.public"
      />
    </div>
  </div>
</template>
<script>
import DocumentLink from './DocumentLink'
import { mapGetters } from 'vuex'

export default {
  components: { DocumentLink },
  computed: {
    ...mapGetters('client', ['importantDocuments']),
    ...mapGetters('member', ['summaryOfBenefits']),
    docs: function() {
      return [...this.summaryOfBenefits, ...this.importantDocuments]
    },
  },
}
</script>
