<template>
  <div>
    <h5 class="mt-20 mb-10">{{ $t('help_center.faq.title') }}</h5>
    <div
      v-for="popularItem in popularItems"
      :key="popularItem.id"
      class="py-10"
    >
      <router-link
        class="p-2 text-blue-500"
        :to="{
          name: 'faq-item',
          params: { id: popularItem.id },
          query: { popular: true },
        }"
        >{{ popularItem.question }}</router-link
      >
    </div>
    <h5 class="mt-20">{{ $t('help_center.faq.topics') }}</h5>
    <router-link
      v-for="category in categories"
      :key="category.id"
      class="flex items-center justify-between border-b border-solid py-20 cursor-pointer"
      :to="{ name: 'faq-category', params: { id: category.id } }"
    >
      <span>{{ category.name }}</span>
      <Icon iconClass="fas fa-chevron-right" />
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('faq', [
      'popularItems',
      'categories',
      'faqLoading',
      'faqError',
    ]),
  },
}
</script>
