<template>
  <div class="w-full max-w-4xl py-20 mx-auto flex-grow flex flex-col">
    <iframe v-if="document" :src="url" class="w-full flex-grow" />
    <BadDocRoute v-else />
  </div>
</template>
<script>
import BadDocRoute from '../404'

export default {
  components: { BadDocRoute },
  props: {
    document: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    url() {
      return /^http/.test(this.document)
        ? this.document
        : `${window.HM_CONFIG.S3_ROOT}/${this.document}`
    },
  },
}
</script>
