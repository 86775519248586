<template>
  <div class="w-full max-w-4xl py-20 mx-auto">
    <h2 class="mt-5 mb-20">{{ $t('help_center.title') }}</h2>
    <div class="md:flex">
      <div class="w-full md:w-6/12 md:pr-30">
        <FaqMainSection />
      </div>
      <div class="w-full md:w-6/12 md:pl-30">
        <ImportantDocuments v-if="showDocs" />
        <div class="border border-grey-300 rounded-lg">
          <h5 class="py-20 px-40 border-b border-grey-300">
            {{ $t('help_center.need_help') }}
          </h5>
          <div class="py-20 px-40">
            <ContactSupport forHelpCenter />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FaqMainSection from './FaqMainSection'
import ImportantDocuments from './ImportantDocuments'
import ContactSupport from '@components/common/ContactSupport'

export default {
  components: { FaqMainSection, ImportantDocuments, ContactSupport },
  computed: {
    ...mapGetters('client', ['importantDocuments']),
    ...mapGetters('member', ['summaryOfBenefits']),
    showDocs() {
      return [...this.importantDocuments, ...this.summaryOfBenefits].length > 0
    },
  },
}
</script>

<style></style>
