<template>
  <div class="w-full max-w-3xl py-20 bg-white mx-auto">
    <Spinner v-if="faqLoading" />
    <Back :route="backRoute" :title="backTitle" class="my-15 inline-block" />
    <div v-if="faq">
      <h4 class="mt-20">{{ title }}</h4>
      <div class="mt-20" v-html="body"></div>
    </div>
    <p
      v-else-if="!faqLoading"
      class="font-bold text-center md:text-left text-xl md:text-2xl mt-30"
    >
      {{ $t('help_center.faq.no_faq_found') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _get from 'lodash/get'
import Spinner from '../../common/Spinner'
export default {
  components: { Spinner },
  props: {
    id: {
      type: Number,
      required: true,
    },
    popular: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('faq', ['itemById', 'faqLoading']),
    faq() {
      return this.itemById(this.id)
    },
    title() {
      return _get(this.faq, 'question')
    },
    body() {
      return _get(this.faq, 'answer')
    },
    category() {
      return _get(this.faq, 'category')
    },
    backRoute() {
      return this.popular || !this.faq
        ? { name: 'help-center' }
        : { name: 'faq-category', params: { id: this.faq.categoryId } }
    },
    backTitle() {
      return this.popular || !this.faq
        ? this.$t('help_center.back_to_landing')
        : this.$t('help_center.faq.back_to', [this.category])
    },
  },
}
</script>

<style></style>
